import { graphql } from "msw";
import { OFFER_ID_THAT_WILL_THROW_ERROR } from "../../constants/literals";
import { mockCart } from "./mockCart";
export var handler = graphql.mutation("AddItemsToCart", function (req, res, ctx) {
  var items = req.variables.itemsToBeAdded.items;

  if (items.some(function (item) {
    return item.offerId == OFFER_ID_THAT_WILL_THROW_ERROR;
  })) {
    return res(ctx.data({
      addItemsToCart: {
        __typename: "CartBatchMutationResponse",
        cart: mockCart,
        errors: [{
          offerId: OFFER_ID_THAT_WILL_THROW_ERROR,
          message: "Item is out of stock."
        }]
      }
    }));
  }

  return res(ctx.data({
    addItemsToCart: {
      __typename: "CartBatchMutationResponse",
      cart: mockCart,
      errors: []
    }
  }));
});